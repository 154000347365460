import React, { useState, useEffect, useMemo } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CallIcon from "@mui/icons-material/Call";
import AnimationIcon from "@mui/icons-material/Animation";
import DrawIcon from "@mui/icons-material/Draw";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import { Avatar, Typography } from "@mui/material";
import ZJ from "../assets/ZJ.png"; // Ensure this path is correct for your project

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ CurrentLocation }: any) {
  const DrawerData = useMemo(
    () => [
      { text: "DASHBOARD", Icon: HomeIcon, url: "/dashboard" },
      { text: "ABOUT THE PROJECT", Icon: InfoIcon, url: "/about" },
      {
        text: "PRECEDENT STUDIES",
        Icon: TextSnippetIcon,
        url: "/precedent-studies",
      },
      { text: "CONTACT", Icon: CallIcon, url: "/contact" },
      { text: "ANIMATIONS", Icon: AnimationIcon, url: "/animations" },
      { text: "RENDERS", Icon: DrawIcon, url: "/renders" },
      { text: "DRAWINGS", Icon: ArchitectureIcon, url: "/drawings" },
      { text: "PRESENTATION", Icon: MovieOutlinedIcon, url: "/presentation" },
      { text: "LOGOUT", Icon: LogoutOutlinedIcon, action: "/login" },
    ],
    []
  );

  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userName, setUserName] = useState(""); // State to store the username

  // Get the current location's index from DrawerData
  useEffect(() => {
    const currentIndex = DrawerData.findIndex(
      (item) => item.text === CurrentLocation
    );
    setSelectedIndex(currentIndex);
  }, [DrawerData, CurrentLocation]);

  // Get the username from localStorage when the component mounts
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    setUserName(userDetails?.username || "Guest"); // Set the username, or fallback to 'Guest'
  }, []);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear local storage when logging out
    window.location.href = "/login"; // Redirect to login
  };

  return (
    <Drawer variant="permanent" open={open}>
      {/* Menu button and logo */}
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton disableRipple>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <MenuIcon color="primary" />
            </IconButton>
          </ListItemIcon>
          <IconButton
            size="large"
            aria-label="logo"
            sx={{
              color: "transparent",
              opacity: open ? 1 : 0,
            }}
          >
            <Link to="/">
              <img
                src={ZJ}
                alt="ZJ"
                style={{ width: "30px", height: "25px" }}
              />
            </Link>
          </IconButton>
        </ListItemButton>
      </ListItem>
      <Divider variant="middle" sx={{ backgroundColor: "#545353" }} />

      {/* Avatar and Username */}
      <Box
        sx={{
          padding: 2.0,
          "&:hover .rotate-on-hover": {
            transform: "rotate(-20deg)", // Rotation applied on hover
          },
        }}
        display={"flex"}
        alignItems={"center"}
      >
        <Avatar
          sx={{ backgroundColor: "transparent", mr: open ? 3 : "auto" }}
          variant="rounded"
        >
          <AccountCircleIcon
            fontSize="large"
            className="rotate-on-hover"
            sx={{
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </Avatar>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{ opacity: open ? 1 : 0 }}
        >
          <Typography sx={{ fontSize: "1.1rem" }}>Welcome</Typography>
          {/* Display the logged-in username */}
          <ListItemText primary={userName} />
        </Box>
      </Box>
      <Divider variant="middle" sx={{ backgroundColor: "#545353" }} />

      {/* List items */}
      <List>
        {DrawerData.map(({ text, Icon, url }, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={{
              display: "block",
            }}
          >
            {text === "LOGOUT" ? (
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            ) : (
              <Link
                to={`${url}`}
                style={{ textDecoration: "none", color: "White" }}
              >
                <ListItemButton
                  onClick={() => handleListItemClick(index)}
                  sx={{
                    borderRadius: "5px",
                    backgroundColor:
                      selectedIndex === index ? "#897258" : "inherit",
                    "&:hover": {
                      backgroundColor:
                        selectedIndex === index ? "#897258" : "inherit",
                    },
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: selectedIndex === index ? "White" : "#897258",
                    }}
                  >
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
