import Carousel from "react-material-ui-carousel";
import slide1 from "./Images/Projects/Vista Conrad/1.webp";
import slide2 from "./Images/Projects/Residence/Residence 134 (1).webp";
import slide3 from "./Images/Projects/Ring Road/003_3 - Photo.webp";
import slide4 from "./Images/Projects/Topical/letopical.webp";
import slide5 from "./Images/Projects/Villas/villas (5).webp";
import slide6 from "./Images/Projects/Horse Fountain/5.webp";
import slide7 from "./Images/Projects/Eiffel Tower/1_QmiiguWSKG1217rA8XfzsQ.webp";
import slide8 from "./Images/Projects/Alpine Woods/1.webp";
import slide9 from "./Images/Projects/Monarh Heights/1.webp";
import slide10 from "./Images/Projects/Jamia Madnia/2.webp";

import SlideItem from "./SlideItem";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "./CSS/Slideshow.css";
import { useMediaQuery, useTheme } from "@mui/material";

const Slideshow = () => {
  const slides = [
    {
      image: slide9,
      id: "LAHORE",
      name: "MONARCH HEIGHTS",
      description: "Monarh Heights - A G+9 mixed-used building in Lahore",
    },
    {
      image: slide7,
      id: "LAHORE",
      name: "EIFFEL TOWER",
      description:
        "We are proud to have proposed the idea and design for the iconic Eiffel Tower in Lahore.",
    },
    {
      image: slide3,
      id: "LAHORE",
      name: "RING ROAD REVAMPMENT",
      description:
        "Our Lahore studio is developing the ring road and JVR commercial area in Bahria town Lahore, seamlessly inserting elements derived from the surrounding architecture..",
    },
    {
      image: slide2,
      id: "ISLAMABAD",
      name: "RESIDENCE 641",
      description:
        "Located in F6 Islamabad, the brief was to make something modern while maintaining privacy for her users. The design boasts sunken sides to bring in natural light and makes a home within a home.",
    },
    {
      image: slide1,
      id: "LAHORE",
      name: "VISTA CONRAD",
      description:
        "Vista Conrad Tower, Sector C Commercial, Bahria Town Lahore—A Z.J Associates project featuring a striking green façade and biophilic design, enhancing urban sustainability",
    },
    {
      image: slide4,
      id: "LAHORE",
      name: "LÉ TOPICAL FACTORY",
      description:
        "Designed with a blend of modernity and utilitarianism, the Topical Printers factory and display area reflects the core ideology of the Topical Group",
    },
    {
      image: slide5,
      id: "UNDISCLOSED",
      name: "FRENCH VILLAS",
      description:
        "A series of French classical homes for a client, blending timeless elegance with modern functionality.  ",
    },
    {
      image: slide6,
      id: "LAHORE",
      name: "HORSE FOUNTAIN",
      description:
        "The use of raging horses as the centerpiece of fountains in Pakistan started here.",
    },
    {
      image: slide8,
      id: "KHANASPUR",
      name: "ALPINE WOODLANDS",
      description:
        "Discover our latest architectural marvel in Khanaspur, Pakistan.",
    },
    {
      image: slide10,
      id: "LAHORE",
      name: "JAMIA MADNIA",
      description:
        "Jamia Madnia Masjid on Raiwind Road, Lahore—crafted with sandstone, bespoke tiles, and sustainable jali systems to enhance Islamic education and eco-conscious design",
    },
  ];

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Carousel
      fullHeightHover={true} // We want the nav buttons wrapper to only be as big as the button element is
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        className: "carousel-nav-button",
        style: {
          backgroundColor: "transparent",
          border: "1px solid white",
          borderRadius: "50%",
        },
      }}
      navButtonsWrapperProps={{
        // Move the buttons to the bottom right.
        className: "carousel-nav-buttons-wrapper",
        style: {
          position: "absolute",
          bottom: "10px",
          top: "40%",
          width: "100px",
          height: "130px",
        },
      }}
      navButtonsAlwaysVisible={isXs ? false : true} // Always show the navigation buttons
      navButtonsAlwaysInvisible={isXs ? true : false}
      NextIcon={<NavigateNextIcon />} // Change the "inside" of the next button to a NextIcon component
      PrevIcon={<NavigateBeforeIcon />} // Change the "inside" of the prev button to a PrevIcon component
      indicators={false}
    >
      {slides.map((item, i) => (
        <SlideItem key={i} item={item} />
      ))}
    </Carousel>
  );
};

export default Slideshow;
